import React, { createContext, Context } from "react";
import packageJson from "../../package.json";
global["appVersion"] = packageJson.version;

const headers = new Headers();
headers.append("pragma", "no-cache");
headers.append("cache-control", "no-cache");
const fetchInitNoCache: RequestInit = { cache: "reload", headers }; // reload replaces cached value

export const VERSION_CACHE_KEY: string = "cache-refreshed-version";
export async function resetCachesAndReload(latestVersion?, eraseLocalStorage?: boolean) {
	console.log("Clearing cache and hard reloading...");
	if (eraseLocalStorage) {
		const v = localStorage.getItem(VERSION_CACHE_KEY);
		localStorage.clear();
		localStorage.setItem(VERSION_CACHE_KEY, v);
	}

	if (caches) {
		// Service worker cache should be cleared with caches.delete()
		const cacheNames = await caches.keys();
		for (const name of cacheNames) {
			caches.delete(name);
		}
	}
	await reloadScripts(latestVersion || Date.now());
	// delete browser cache and hard reload
	window.location.reload();
}

async function reloadScripts(key: string | number): Promise<any> {
	const scripts: HTMLCollectionOf<HTMLScriptElement> = document.getElementsByTagName("script");
	const scriptsLoadedPromises: Promise<any>[] = [];

	for (let i = 0; i < scripts.length; i++) {
		const aScript: HTMLScriptElement = scripts[i];
		console.log(aScript.src);
		const isLocalScript: boolean = aScript.src.indexOf(window.location.origin) === 0;
		console.log("isLocalScript:", isLocalScript);
		if (isLocalScript && aScript.src.indexOf("/static/js") > -1) {
			// take first part of script name, minus querystring
			let querystringStartIndex: number = aScript.src.indexOf("?");
			if (querystringStartIndex === -1) querystringStartIndex = undefined;
			const src: string = aScript.src.substring(0, querystringStartIndex);
			console.log("src:", src);
			// const newSrc: string = src + "?v=" + key;
			// console.log("newSrc:", newSrc);
			// aScript.src = newSrc;
			scriptsLoadedPromises.push(fetch(src, fetchInitNoCache));
		}
	}
	return Promise.all(scriptsLoadedPromises);
}

export default class CacheBuster extends React.Component {
	newVersionCheckInterval;
	private _isMounted: boolean = false;

	state = {
		loading: false,
		isLatestVersion: true,
		latestVersion: global["appVersion"],
		refreshCacheAndReload: () => {
			resetCachesAndReload(this.state.latestVersion, true);
		},
	};

	componentDidMount() {
		this._isMounted = true;
		this.doCacheCheck();
		this.newVersionCheckInterval = setInterval(() => {
			this.doCacheCheck();
		}, 3600000); // check once an hour // 1000 * 60 * 60 3600000
	}

	componentWillUnmount() {
		this._isMounted = false;
		clearInterval(this.newVersionCheckInterval);
	}

	doCacheCheck() {
		if (!this._isMounted) return;
		fetch(`/meta.json?refresh=${Date.now()}`, fetchInitNoCache)
			.then((response) => response.json())
			.then((meta) => {
				if (!this._isMounted) return;
				const latestVersion = meta.version;
				if (localStorage.getItem(VERSION_CACHE_KEY) == latestVersion) {
					console.log(
						`You already refreshed to the latest version - ${latestVersion}. No cache refresh needed.`
					);
					this.setState({ loading: false, isLatestVersion: true });
					return;
				}
				const currentVersion = global["appVersion"];
				console.log("CacheBuster: currentVersion, latestVersion:", currentVersion, latestVersion);
				const shouldForceRefresh = latestVersion !== currentVersion; //semverGreaterThan(latestVersion, currentVersion);
				if (shouldForceRefresh) {
					console.log(`We have a new version - ${latestVersion}. Should force refresh`);
					localStorage.setItem(VERSION_CACHE_KEY, latestVersion);
					this.setState({ loading: false, isLatestVersion: false, latestVersion });
					this.state.refreshCacheAndReload();
				} else {
					console.log(`You already have the latest version - ${latestVersion}. No cache refresh needed.`);
					this.setState({ loading: false, isLatestVersion: true });
				}
			})
			.catch((err) => {
				if (!this._isMounted) return;
				console.error("Cache buster error:", err);
				this.setState({ loading: false, isLatestVersion: true });
			});
	}

	render() {
		if (!this._isMounted) return null;
		const { loading, isLatestVersion, latestVersion, refreshCacheAndReload } = this.state;
		return (
			<CacheContext.Provider
				value={{
					loading,
					latestVersion,
					isLatestVersion,
					refreshCacheAndReload,
				}}
			>
				{this.props.children}
			</CacheContext.Provider>
		);
	}
}

export interface ICacheContext {
	loading: boolean;
	latestVersion: string;
	isLatestVersion: boolean;
	refreshCacheAndReload: () => any;
}

export const CacheContext: Context<ICacheContext> = createContext<ICacheContext>({
	loading: true,
	latestVersion: undefined,
	isLatestVersion: true,
	refreshCacheAndReload: null,
});
